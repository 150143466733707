<template>
    <el-form-item :label="$t('calendar.color')">
        <el-color-picker
            v-model="selectedColor"
            :predefine="predefinedColors"
            @change="colorChanged"
        />
    </el-form-item>
</template>
<script>
import Vue from 'vue';
import { ColorPicker } from 'element-ui';

Vue.use(ColorPicker);

export default {
    props: {
        selectedColorProp: {
            type:    String,
            default: '',
        },
    },

    data() {
        return {
            selectedColor:    '#20a0ff',
            predefinedColors: [
                '#c92918', '#00d1be', '#0097d1', '#3a983a', '#0071d1', '#8433de', '#db33de', '#de3372', '#33deaf', '#9a8974',
            ],
        };
    },

    watch: {
        selectedColorProp() {
            this.selectedColor = this.selectedColorProp;
        },
    },

    methods: {
        colorChanged() {
            this.$emit('change', this.selectedColor);
        },
    },
};
</script>
